.A4page {
    width: 297mm;
    height: calc(210mm - 1px);
    position: relative;
    margin: 0;
    border: 0;
    padding: 6mm;
    break-after: always;
}

.A5page {
    width: 148.5mm;
    height: calc(210mm - 1px);
    position: relative;
    margin: 0;
    border: 0;
    padding: 6mm;
    break-after: always;
    border: 1px solid #CCC;
}

.book-page {
    width: 136.5mm;
    height: 198mm;
    position: absolute;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-align: left;
}

.cover {
    text-align: center;
    text-indent: 0;
}

.story p {
    font-size: 3mm;
    line-height: 5mm;
    text-indent: 4mm;
}

p.noindent {
    text-indent: 0;
}

.book-page-left {
    left: 6mm;
    top: 6mm;
}

.book-page-right {
    right: 6mm;
    top: 6mm;
}

.page-number {
    position: absolute;
}

.book-page-left .page-number {
    left: 5mm;
    bottom: 0mm;
}

.book-page-right .page-number {
    right: 5mm;
    bottom: 0mm;
}

.page-image {
    position: absolute;
    background-size: cover; 
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    z-index: -1;
    border-radius:1.5mm;
    border: 1px solid #FFF;
    box-shadow: inset -0.5mm -0.5mm 2mm 2mm #FFF;
    overflow: hidden;
}

.double-dice-logo {
    position: absolute;
    left: 20mm;
    top: 20mm;
}

h1 {
    font-size: 8.5mm;
    line-height: 9.5mm;
    color: #FFF;
    text-shadow: 0mm 0mm 1mm #000, 0mm 0mm 2mm #000;
}

h2 {
    font-size: 6.5mm;
    line-height: 7.5mm;
    color: #FFF;
    text-shadow: 0mm 0mm 1mm #000, 0mm 0mm 2mm #000;
}

h3 {
    font-size: 4mm;
    line-height: 4.5mm;
}

h4 {
    margin-top: 1.5mm;
    margin-bottom: 0;
}


.spacer {
    display: block;

}

.logo-container {
    position: absolute;
    left: 15mm;
    top: 10mm;
}

.logo-container .first {
    position: absolute;
    left: 0mm;
    top: 0mm;
    transform: rotate(-21deg);
}

.logo-container .second {
    position: absolute;
    left: 4mm;
    top: 4mm;
    transform: rotate(21deg);
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

blockquote {
    margin: 2mm 0 2mm 0;
    padding: 2mm;
    border: 1px dashed #000;
    text-indent: 0;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.bottom {
    position: absolute;
    bottom: 2mm;
    width: 100%;
}

.padded5 {
    padding-bottom: 5mm;
}
.padded3 {
    padding-bottom: 3mm;
}

.big {
    font-size: 120%;
}

.character-sheet {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1mm;
    row-gap: 0.75mm;
    grid-template-rows: auto;
    border: 1px dashed #000;
    text-indent: 0;
    padding: 0.5mm;
}

.character-sheet .bb{
    border-bottom: 1px solid #000; 
}

.character-sheet .bt{
    border-top: 1px solid #000; 
}

.fa, .far, .fal, .fad, .fas {
    text-indent: 0 !important;
}



.nopagenumber .page-number {
    display: none;
}

.gray {
    color: #CCC;
}

.nocharsheetborder .character-sheet {
    border:none;
    padding: 0mm;
}

.boxed {
    border: 1px solid #000;
    padding: 0.75rem;
}

.clickable {
    cursor: pointer;
}