
.page-editor {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 70rem;

    text-indent: 0;
}

.pageSelector {
    height: 8rem;
}

input {
    height: 2rem;
    width: 45rem;
}