@page {
    size: A4 landscape;
    margin: 0mm;
}

html,
body {
    size: A4 landscape;
    margin: 0;
    padding: 0;
}


html {
    text-align: center;
    font-family: 'baskerville', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3mm;
    line-height: 5mm;
    text-indent: 4mm;
    /*background: rgb(255, 233, 202);*/
}

@media print {
    * {
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
        color-adjust: exact !important;                 /* Firefox 48 – 96 */
        print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
    }
}

* {
    box-sizing: border-box;
}
