@font-face {
    font-family: 'baskerville';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./LibreBaskerville-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'baskerville';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('./LibreBaskerville-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'baskerville';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('./LibreBaskerville-Bold.ttf') format('truetype');
}